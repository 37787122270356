<template>
    <div class="modal fade show" id="indoorAreaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="indoorAreaForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 v-if="isCreation" class="modal-title">{{ $t("area_indoorAreaCreation") }}</h5>
                        <h5 v-else class="modal-title">{{ $t("area_indoorAreaUpdate") }}</h5>
                        <button type="button" @click="cancelIndoorArea" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label>{{ $t("area_indoorAreaName") }} *</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                </div>
                                <input id="indoorAreaNameInput" type="text" class="form-control" :placeholder="$t('area_inputInfoIndoorAreaName')" v-model="indoorAreaName" />
                            </div>
                            <div v-if="!$v.indoorAreaName.required" class="invalid-feedback">
                                {{ $t("error_fieldIsRequired") }}
                            </div>
                            <div v-else-if="!$v.indoorAreaName.minLen" class="invalid-feedback">
                                {{ $t("error_minLengthMsg", [$v.indoorAreaName.$params.minLen.min]) }}
                            </div>
                            <span class="form-text text-muted">{{ $t("area_detailInfoIndoorAreaName") }}</span>
                        </div>
                        <div class="form-group">
                            <label>{{ $t("area_color") }}</label>
                            <div class="input-group">
                                <input id="indoorAreaColorInput" class="form-control" type="color" v-model="indoorAreaColor" />
                            </div>
                            <span class="form-text text-muted">{{ $t("area_detailInfoIndoorAreaColor") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="indoorAreaModalCancelButton" type="button" class="btn btn-secondary" @click="cancelIndoorArea" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button v-if="isCreation" id="indoorAreaModalSubmitButton" type="button" @click="submitIndoorArea" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_create") }}</button>
                        <button v-else id="indoorAreaModalSubmitButton" type="button" @click="submitIndoorArea" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_update") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import commonVueHelper from '../../helpers/commonVueHelper';
export default {
    data() {
        return {
            indoorAreaName: "",
            indoorAreaColor: "#3388ff",
            isCreation: false,
            isEditPolygon: false
        };
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentIndoorArea: function(indoorArea) {
            console.log("Component(indoorAreaModal)::watch(currentIndoorArea) - called with : ", indoorArea);
            if (indoorArea.state == "FINALIZE_CREATION" || indoorArea.state == "FINALIZE_EDITION") {
                this.isCreation = indoorArea.state == "FINALIZE_CREATION" ? true : false;
                this.indoorAreaName = indoorArea.name;
                this.indoorAreaColor = "#" + indoorArea.color;
                $("#indoorAreaModal").modal("show");
            } else {
                this.isEditPolygon = indoorArea.state == "EDIT" ? true : false;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentIndoorArea"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        indoorAreaName: {
            required,
            minLen: minLength(2)
        }
    },
    methods: {
        ...mapActions(["createIndoorArea", "updateIndoorArea", "updateIndoorAreaPolygon", "setCurrentIndoorArea", "getIndoorAreaById"]),
        submitIndoorArea() {
            console.log("Component(indoorAreaModal)::submitIndoorArea() - called");
            let indoorAreaData = this.currentIndoorArea;
            indoorAreaData.name = this.indoorAreaName;
            indoorAreaData.color = this.indoorAreaColor.substring(1); // remove '#' character
            if (this.isCreation) {
                this.createIndoorArea(indoorAreaData);
            } else if (this.isEditPolygon) {
                this.updateIndoorAreaPolygon(indoorAreaData);
            } else {
                this.updateIndoorArea(indoorAreaData);
            }
            $("#indoorAreaModal").modal("hide");

            // Reset current indoor area in store
            this.setCurrentIndoorArea({ id: "", state: "" });
        },
        cancelIndoorArea() {
            console.log("Component(indoorAreaModal)::cancelIndoorArea() - called");
            if (this.currentIndoorArea.state === "FINALIZE_CREATION") {
                this.setCurrentIndoorArea({ id: "", state: "" });
            } else if (this.currentIndoorArea.state === "FINALIZE_EDITION") {
                this.setCurrentIndoorArea({ id: "", state: "CANCEL_EDIT" });
                // Renitialisation of tooltip
                commonVueHelper.initToolTips();// Renitialisation of tooltip
            } else {
                this.setCurrentIndoorArea({ id: "", state: "" });
            }
        },
        onSubmit() {
            console.log("Component(indoorAreaModal)::onSubmit() - called");
            if (!this.$v.$invalid) {
                this.submitIndoorArea();
            }
        }
    }
};
</script>

<style scoped></style>
